import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNoteContext } from '../context/NoteContext';
import { DatabaseService } from '../services/db';
import { generateUUID } from '../utils/uuid';
import { Note } from '../types';
import { editorShortcutListener } from '../utils/editor';
import MDEditor from '@uiw/react-md-editor';

const AddNote: React.FC<{ refreshNotes: () => void }> = ({refreshNotes}) => {
    const { state, dispatch } = useNoteContext();
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);

    // local state
    const [newNoteContent, setNewNoteContent] = useState('');

    const saveNote = async () => {
        if (newNoteContent.trim()) {
            const newNote: Note = {
                id: Date.now(),
                uuid: generateUUID(),
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                content: newNoteContent,
            };
            await DatabaseService.saveNote(newNote);
            dispatch({ type: 'SET_ADDING_NOTE', payload: false });
            setNewNoteContent('');
            refreshNotes()
        }
    };

    const cancelAddNote = () => {
        dispatch({ type: 'SET_ADDING_NOTE', payload: false });
    };

    const handleKeyDown = editorShortcutListener({save: saveNote, cancel: cancelAddNote})

    useEffect(() => {
        if (state.addingNote && containerRef.current) {
            // 给一个小延迟确保编辑器已经完全渲染
            setTimeout(() => {
                const textarea = containerRef.current?.querySelector('textarea');
                if (textarea) {
                    textarea.focus();
                }
            }, 100);
        }
    }, [state.addingNote]);

    return state.addingNote ? 
        <div className="add-note-container" ref={containerRef}>
            <div className={`md-editor-wrapper ${!newNoteContent ? 'empty' : ''}`} data-placeholder={t('action.add_note')}>
                <MDEditor
                    value={newNoteContent}
                    onChange={(value) => setNewNoteContent(value || '')}
                    preview="edit"
                    onKeyDown={handleKeyDown}
                    height={300}
                    className="mb-4"
                />
            </div>
            <div>
                <button onClick={saveNote} className="action-primary-button">
                    {t('action.save')}
                </button>
                <button onClick={cancelAddNote} className="action-secondary-button ml-2">
                    {t('action.cancel')}
                </button>
            </div>
        </div>
        : null;
}

export default AddNote; 