import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface PullToRefreshProps {
  onRefresh: () => Promise<void>;
  children: React.ReactNode;
}

const PullToRefresh: React.FC<PullToRefreshProps> = ({ onRefresh, children }) => {
  const { t } = useTranslation();
  const [startY, setStartY] = useState<number | null>(null);
  const [pulling, setPulling] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const handleTouchStart = (e: TouchEvent) => {
    // 只有在页面顶部才启用下拉刷新
    if (window.scrollY === 0) {
      setStartY(e.touches[0].pageY);
    }
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (!startY) return;

    const currentY = e.touches[0].pageY;
    const diff = currentY - startY;

    if (diff > 50) {
      setPulling(true);
    }
  };

  const handleTouchEnd = async () => {
    if (pulling && !refreshing) {
      setRefreshing(true);
      try {
        await onRefresh();
      } finally {
        setRefreshing(false);
        setPulling(false);
        setStartY(null);
      }
    }
    setPulling(false);
    setStartY(null);
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [pulling, refreshing, startY]);

  return (
    <div className="relative min-h-screen">
      {(pulling || refreshing) && (
        <div className="fixed top-0 left-0 right-0 bg-gray-100 py-2.5 text-center z-50 text-sm text-gray-600 shadow-md中">
          {refreshing ? t('sync.syncing') : t('sync.pull_to_sync')}
        </div>
      )}
      {children}
    </div>
  );
};

export default PullToRefresh; 