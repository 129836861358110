import { openDB, DBSchema, IDBPDatabase } from 'idb';
import { Note, Todo } from '../types';

// 添加一个迁移用的接口
interface NoteWithLegacyFields extends Note {
  ctime?: string;  // 旧字段，可选
  mtime?: string;  // 旧字段，可选
}

// ... 在 NotesDB interface 中添加
interface NotesDB extends DBSchema {
  notes: {
    key: string;
    value: NoteWithLegacyFields;  // 使用新的接口类型
    indexes: { 
      'by-updated': string, 
      'by-created': string,
      'by-mtime'?: string,  // 添加旧索引，使用可选属性
      'by-ctime'?: string   // 添加旧索引，使用可选属性
    };
  };
  sync: {
    key: 'lastSync';
    value: {
      timestamp: string;
    };
  };
  todos: {
    key: string;
    value: Todo;
    indexes: {
      'by-parent': string;
      'by-updated': string;
    };
  };
}

export class DatabaseService {
  private static dbName = 'notes-db';
  private static db: IDBPDatabase<NotesDB> | undefined;
  private static isInitialized = false;

  static async init() {
    if (DatabaseService.isInitialized) return;
    
    const latestVersion = 3;
    DatabaseService.db = await openDB<NotesDB>(DatabaseService.dbName, latestVersion, {
      upgrade(db, oldVersion, newVersion, transaction) {
        console.log("ugrading. ", oldVersion, "->", newVersion)

        if (oldVersion < 1) {
          const notesStore = db.createObjectStore('notes', { keyPath: 'uuid' });
          notesStore.createIndex('by-updated', 'updated_at');
          notesStore.createIndex('by-created', 'created_at');
          db.createObjectStore('sync');
        }
      
        if (oldVersion < 2) {
          // 直接从 transaction 拿到已经存在的 store
          const notesStore = transaction.objectStore('notes');
          const indexNames = Array.from(notesStore.indexNames);
      
          if (indexNames.includes('by-mtime')) {
            notesStore.deleteIndex('by-mtime');
          }
          if (indexNames.includes('by-ctime')) {
            notesStore.deleteIndex('by-ctime');
          }
      
          // 这里再做索引创建
          if (!indexNames.includes('by-updated')) {
            notesStore.createIndex('by-updated', 'updated_at');
          }
          if (!indexNames.includes('by-created')) {
            notesStore.createIndex('by-created', 'created_at');
          }

          // 下面是关键：把旧的 ctime / mtime 改成新的 created_at / updated_at
          const allNotesPromise = notesStore.getAll();
          allNotesPromise.then((allNotes: NoteWithLegacyFields[]) => {
            allNotes.forEach(oldRecord => {
              const newRecord: Note = {
                ...oldRecord,
                created_at: oldRecord.ctime!,  // 使用类型断言，因为我们知道旧数据一定有这个字段
                updated_at: oldRecord.mtime!,
              };

              delete (newRecord as any).ctime;
              delete (newRecord as any).mtime;

              console.log("newRecord: ", newRecord)
              notesStore.put(newRecord);
            });
          });
        }
        if (oldVersion < 3) {
          // init TODO store
          const todoStore = db.createObjectStore('todos', { keyPath: 'uuid' });
          todoStore.createIndex('by-parent', 'parent_uuid');
          todoStore.createIndex('by-updated', 'updated_at');
        }

        console.log("upgrade done");
      }
    });
    DatabaseService.isInitialized = true;
  }

  private static async ensureInitialized() {
      if (!DatabaseService.isInitialized) {
        await DatabaseService.init();
      }
    }
  
    static async saveTodo(todo: Todo) {
      await DatabaseService.ensureInitialized();
      if (!todo.uuid) {
        todo.uuid = crypto.randomUUID();
      }
      todo.updated_at = new Date().toISOString();
      await DatabaseService.db!.put('todos', todo);
    }
  
    static async getTodosByParentId(parentId: string): Promise<Todo[]> {
      await DatabaseService.ensureInitialized();
      const tx = DatabaseService.db!.transaction('todos', 'readonly');
      const index = tx.store.index('by-parent');
      return await index.getAll(parentId);
    }
  
    static async deleteTodo(id: string) {
      await DatabaseService.ensureInitialized();
      await DatabaseService.db!.delete('todos', id);
    }
  
    static async toggleTodoComplete(id: string) {
      await DatabaseService.ensureInitialized();
      const todo = await DatabaseService.db!.get('todos', id);
      if (todo) {
        todo.completed = !todo.completed;
        await DatabaseService.saveTodo(todo);
      }
    }

  static async saveNote(note: Note, updateTimestamp: boolean = true) {
    if (updateTimestamp) {
      note.updated_at = new Date().toISOString();
    }
    await DatabaseService.db!.put('notes', note);
  }

  static async getAllNotes(): Promise<Note[]> {
    const tx = DatabaseService.db!.transaction('notes', 'readonly');
    const store = tx.objectStore('notes');
    const notes = await store.getAll();
    return notes.filter(note => !note.deleted_at);
  }

  // TODO: sync note based on lastSync instead
  static async getLastSync() {
    return await DatabaseService.db!.get('sync', 'lastSync');
  }

  static async setLastSync(timestamp: string) {
    await DatabaseService.db!.put('sync', { timestamp }, 'lastSync');
  }

  static async markNoteAsDeleted(note: Note) {
    const now = new Date().toISOString();
    note.updated_at = now
    const tx = DatabaseService.db!.transaction('notes', 'readwrite');
    const store = tx.objectStore('notes');
    if (note) {
      note.deleted_at = now
      await store.put(note);
    }
    await tx.done;
  }
}

export async function markNoteAsDeleted(db: IDBPDatabase, noteId: number) {
}