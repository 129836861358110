import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DatabaseService } from '../services/db';
import { Todo } from '../types';

export const ResolutionsPanel: React.FC = () => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [todos, setTodos] = useState<Todo[]>([]);
  const [newTodoContent, setNewTodoContent] = useState('');

  useEffect(() => {
    loadRootTodos();
  }, []);

  const loadRootTodos = async () => {
    const rootTodos = await DatabaseService.getTodosByParentId("");
    console.log("rootTodos loaded: ", rootTodos);
    setTodos(rootTodos.sort((a, b) => a.order - b.order));
  };

  const handleAddTodo = async () => {
    console.log("new todo content: ", newTodoContent);
    if (!newTodoContent.trim()) return;

    const newTodo: Todo = {
      uuid: crypto.randomUUID(),
      content: newTodoContent,
      completed: false,
      parent_uuid: "",
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      order: todos.length
    };

    await DatabaseService.saveTodo(newTodo);
    setNewTodoContent('');
    loadRootTodos();
  };

  const handleDelete = async (id: string) => {
    await DatabaseService.deleteTodo(id);
    loadRootTodos();
  };

  return (
    <div className={`resolutions-panel hidden md:block ${!isExpanded ? 'collapsed' : ''}`}>
      <button 
        onClick={() => setIsExpanded(!isExpanded)}
        className="absolute left-[-20px] top-4 bg-white border border-gray-200 rounded-l-lg p-2 hover:bg-gray-50"
      >
        {isExpanded ? '›' : '‹'}
      </button>

      <div className="panel-content">
        <h2 className="text-2xl font-bold mb-4">{t('resolutions.title')}</h2>
        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex gap-2 mb-4">
            <input
              type="text"
              value={newTodoContent}
              onChange={(e) => setNewTodoContent(e.target.value)}
              placeholder={t('resolutions.placeholder.new_main')}
              className="flex-1 px-2 py-1 border rounded"
            />
            <button
              onClick={handleAddTodo}
              className="action-primary-button"
            >
              {t('resolutions.action.add')}
            </button>
          </div>
          
          <div className="todo-list">
            {todos.map(todo => (
              <TodoItem
                key={todo.uuid}
                todo={todo}
                onDelete={handleDelete}
                level={0}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};



interface TodoItemProps {
  todo: Todo;
  onDelete: (id: string) => void;
  level: number;
}

export const TodoItem: React.FC<TodoItemProps> = ({ todo, onDelete, level }) => {
  const [subTodos, setSubTodos] = useState<Todo[]>([]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [newTodoContent, setNewTodoContent] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    loadSubTodos();
  }, [todo.uuid]);

  const loadSubTodos = async () => {
    const todos = await DatabaseService.getTodosByParentId(todo.uuid);
    setSubTodos(todos.sort((a, b) => a.order - b.order));
  };

  const handleAddSubTodo = async () => {
    if (!newTodoContent.trim()) return;
    
    const newTodo: Todo = {
      uuid: crypto.randomUUID(),
      content: newTodoContent,
      completed: false,
      parent_uuid: todo.uuid,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      order: subTodos.length
    };

    await DatabaseService.saveTodo(newTodo);
    setNewTodoContent('');
    loadSubTodos();
  };

  const toggleComplete = async () => {
    await DatabaseService.toggleTodoComplete(todo.uuid);
  };

  return (
    <div className="ml-4">
      <div className="flex items-center gap-2 my-2">
        <input
          type="checkbox"
          checked={todo.completed}
          onChange={toggleComplete}
          className="form-checkbox"
        />
        <span className={`flex-1 ${todo.completed ? 'line-through text-gray-400' : ''}`}>
          {todo.content}
        </span>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-gray-500 hover:text-gray-700"
        >
          {isExpanded ? '▼' : '▶'}
        </button>
        <button
          onClick={() => onDelete(todo.uuid)}
          className="text-red-500 hover:text-red-700"
        >
          ×
        </button>
      </div>

      {isExpanded && (
        <div className="ml-4">
          {subTodos.map(subTodo => (
            <TodoItem
              key={subTodo.uuid}
              todo={subTodo}
              onDelete={onDelete}
              level={level + 1}
            />
          ))}
          <div className="flex gap-2 mt-2">
            <input
              type="text"
              value={newTodoContent}
              onChange={(e) => setNewTodoContent(e.target.value)}
              placeholder={t("resolutions.placeholder.new_sub")}
              className="flex-1 px-2 py-1 border rounded"
            />
            <button
              onClick={handleAddSubTodo}
              className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {t("resolutions.action.add_sub")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};