import React from 'react';
import { Note } from '../types';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { formatDateTime, formatRelativeTime } from '../utils/dateFormat';
import { editorShortcutListener } from '../utils/editor';
import AddNote from './AddNote';

interface NotesPanelProps {
  notes: Note[];
  editingNote: Note | null;
  onEditNote: (note: Note) => void;
  onDeleteNote: (note: Note) => void;
  onSaveNote: () => void;
  onCancelEdit: () => void;
  setEditingNote: (note: Note) => void;
  refreshNotes: () => void;
}

export const NotesPanel: React.FC<NotesPanelProps> = ({
  notes,
  editingNote,
  onEditNote,
  onDeleteNote,
  onSaveNote,
  onCancelEdit,
  setEditingNote,
  refreshNotes
}) => {
  const { t } = useTranslation();
  const editNoteHandleKeyDown = editorShortcutListener({save: onSaveNote, cancel: onCancelEdit});

  return (
    <div className="notes-panel">
      <div className="notes-container">
        <div className="flex justify-between items-center">
          <h1 className='site-title'>{t('my_notes')}</h1>
        </div>
        <AddNote refreshNotes={refreshNotes}/>

        {notes.map(note => (
          <article key={note.uuid} className="note-article">
            <header className="note-header">
              <h1>{formatDateTime(note.created_at)}</h1>
              <div className="note-meta flex justify-between items-center">
                <span>{t("note.updated_at", {time: formatRelativeTime(note.updated_at, t)})}</span>
                {note.created_at !== note.updated_at && 
                  <span>{t("note.created_at", {time: formatRelativeTime(note.created_at, t)})}</span>
                }
              </div>
            </header>

            {editingNote?.uuid === note.uuid ? (
              <div>
                <textarea
                  className='note-editor'
                  value={editingNote.content}
                  onChange={(e) => setEditingNote({ ...editingNote, content: e.target.value })}
                  onKeyDown={editNoteHandleKeyDown}
                />
                <button 
                  onClick={onSaveNote} 
                  className="action-primary-button"
                >
                  {t('action.save')}
                </button>
                <button 
                  onClick={onCancelEdit} 
                  className="action-secondary-button ml-2"
                >
                  {t('action.cancel')}
                </button>
              </div>
            ) : (
              <div>
                <section className="note-markdown-content">
                  <ReactMarkdown>{note.content}</ReactMarkdown>
                </section>
                <button 
                  onClick={() => onEditNote(note)} 
                  className="action-primary-button"
                >
                  {t('action.edit')}
                </button>
                <button 
                  onClick={() => onDeleteNote(note)} 
                  className="action-danger-button ml-2"
                >
                  {t('action.delete')}
                </button>
              </div>
            )}
          </article>
        ))}
      </div>
    </div>
  );
}; 