import React from 'react';
import { SortField, SortOption } from '../types/index';
import { useNoteContext } from '../context/NoteContext';
import { sortNotes } from '../utils/note';
import { DatabaseService } from '../services/db';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

interface ToolbarProps {
  onSync: () => void;
}

export function Toolbar({ 
  onSync, 
}: ToolbarProps) {
  const { state, dispatch } = useNoteContext();

  const { isSyncing, sortOption, dateRange, syncDays, isLoggedIn } = state;
  const { t } = useTranslation();

  const handleSortChange = (newSortOption: SortOption) => {
    dispatch({ type: 'SET_SORT_OPTION', payload: newSortOption });
    dispatch({ type: 'SET_NOTES', payload: sortNotes(newSortOption, state.notes) });
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    dispatch({ type: 'LOGOUT' });
  };

  const handleSyncDaysChange = (days: number) => {
    dispatch({ type: 'SET_SYNC_DAYS', payload: days });
  };

  const handleDateRangeChange = (newRange: { startDate: string; endDate: string }) => {
    dispatch({ type: 'SET_DATE_RANGE', payload: newRange });
    filterNotesByDateRange(newRange);
  };
  const filterNotesByDateRange = async (range: { startDate: string; endDate: string }) => {
    const allNotes = await DatabaseService.getAllNotes();
    const filteredNotes = allNotes.filter(note => {
      const noteDate = note.created_at.split('T')[0];
      return noteDate >= range.startDate && noteDate <= range.endDate;
    });
    dispatch({ type: 'SET_NOTES', payload: sortNotes(state.sortOption, filteredNotes) });
  };




  // TODO: move this logic into reducer
  const loadLocalNotes = async () => {
    const localNotes = await DatabaseService.getAllNotes();
    const sortedNotes = sortNotes(sortOption, localNotes);
    dispatch({ type: 'SET_NOTES', payload: sortedNotes });

    if (sortedNotes.length > 0) {
      const dates = sortedNotes.map(note => note.created_at.split('T')[0]);
      dispatch({ type: 'SET_DATE_RANGE', payload: {
        startDate: dates[dates.length - 1],
        endDate: dates[0]
      }});
    } else {
      const today = new Date().toISOString().split('T')[0];
      dispatch({ type: 'SET_DATE_RANGE', payload: {
        startDate: today,
        endDate: today
      }});
    }
  };

  const onAddNote = async () => {
    dispatch({type: 'SET_ADDING_NOTE', payload: true})
  }

  return (
    <div className="toolbar">
      {isLoggedIn ? (
        <>
          <div className="flex flex-col md:flex-row w-full gap-2 p-2">
            {/* 日期选择器组 */}
            <div className="flex flex-wrap items-center gap-2 min-w-0 w-full md:w-auto">
              <input
                type="date"
                value={dateRange.startDate}
                max={dateRange.endDate}
                onChange={(e) => handleDateRangeChange({
                  ...dateRange,
                  startDate: e.target.value
                })}
                className="p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 flex-1 min-w-0"
              />
              <span className="whitespace-nowrap">{t('to')}</span>
              <input
                type="date"
                value={dateRange.endDate}
                min={dateRange.startDate}
                onChange={(e) => handleDateRangeChange({
                  ...dateRange,
                  endDate: e.target.value
                })}
                className="p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 flex-1 min-w-0"
              />
            </div>

            {/* 排序控制组 */}
            <div className="flex items-center gap-2 min-w-0 w-full md:w-auto">
              <select
                value={sortOption.field}
                onChange={(e) => handleSortChange({
                  ...sortOption,
                  field: e.target.value as SortField
                })}
                className="p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 flex-1 min-w-0"
              >
                <option value="created_at">{t('creation_time')}</option>
                <option value="updated_at">{t('modification_time')}</option>
              </select>
              <button
                className={`p-2 border border-gray-300 rounded bg-white hover:bg-gray-100 transition ${sortOption.direction}`}
                onClick={() => handleSortChange({
                  ...sortOption,
                  direction: sortOption.direction === 'desc' ? 'asc' : 'desc'
                })}
              >
                {sortOption.direction === 'desc' ? '↓' : '↑'}
              </button>
            </div>

            {/* 控制按钮组 */}
            <div className="flex flex-wrap items-center gap-2 min-w-0 w-full md:w-auto md:ml-auto">
              <button onClick={onAddNote} className="action-primary-button whitespace-nowrap">
                {t('action.add_note')}
              </button>
              <select 
                value={syncDays} 
                onChange={(e) => handleSyncDaysChange(Number(e.target.value))}
                className="flex-1 md:flex-none min-w-0"
              >
                <option value={7}>{t('sync.last_x_days', { days: 7 })}</option>
                <option value={14}>{t('sync.last_x_days', { days: 14 })}</option>
                <option value={30}>{t('sync.last_x_days', { days: 30 })}</option>
                <option value={-1}>{t('sync.all_days')}</option>
              </select>
              <button 
                onClick={onSync} 
                disabled={isSyncing}
                className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition whitespace-nowrap"
              >
                {isSyncing ? t('sync.syncing') : t('sync.sync_notes')}
              </button>
              <button onClick={handleLogout} className="whitespace-nowrap action-secondary-button">{t('action.logout')}</button>
              <LanguageSelector changeLanguage={changeLanguage}/>
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center gap-2 p-2 w-full justify-end">
          <LanguageSelector changeLanguage={changeLanguage}/>
        </div>
      )}
    </div>
  );
} 

interface LanguageSelectionProps {
  changeLanguage: (lng: string) => void; // 指定类型
}

function LanguageSelector({changeLanguage}: LanguageSelectionProps){
  return <select onChange={(e) => changeLanguage(e.target.value)} className="p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500">
              <option value="zh">中文</option>
              <option value="en">English</option>
            </select>
}