import React, { useEffect, useRef, useState } from 'react';
import { Note, SyncRequest } from './types';
import { ApiService } from './services/api';
import { DatabaseService } from './services/db';
import { Toolbar } from './components/Toolbar';
import './styles/index.css';
import { sortNotes } from './utils/note';
import { useTranslation } from 'react-i18next';
import { useNoteContext } from './context/NoteContext';
import ToastBox from './components/ToastBox';
import Login from './components/Login';
import { editorShortcutListener } from './utils/editor';
import PullToRefresh from './components/PullToRefresh';
import { NotesPanel } from './components/NotesPanel';
import { ResolutionsPanel } from './components/ResolutionsPanel';

function getTimeStamp(date: Date | null): string | null {
  if(date == null) {
    return null
  }
  return `${date.toISOString().split('T')[0]}T23:59:59Z`;
}

function App() {
  const { t } = useTranslation();
  const { state, dispatch } = useNoteContext();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch({ type: 'LOGIN' });
      initializeApp();
    }
  }, []);

  const initializeApp = async () => {
    await DatabaseService.init();
    await loadLocalNotes();
  };

  const loadLocalNotes = async () => {
    const localNotes = await DatabaseService.getAllNotes();
    const sortedNotes = sortNotes(state.sortOption, localNotes);
    dispatch({ type: 'SET_NOTES', payload: sortedNotes });

    const today = new Date().toISOString().split('T')[0];
    if (sortedNotes.length > 0) {
      const dates = sortedNotes.map(note => note.created_at.split('T')[0]);
      dispatch({ type: 'SET_DATE_RANGE', payload: {
        startDate: dates[dates.length - 1],
        endDate: today
      }});
    } else {
      dispatch({ type: 'SET_DATE_RANGE', payload: {
        startDate: today,
        endDate: today
      }});
    }
  };

  const handleManualSync = async () => {
    dispatch({ type: 'SET_SYNCING', payload: true });
    try {
      var endDate: Date | null = new Date();
      var startDate: Date | null = new Date();
      if(state.syncDays >= 0) {
        startDate.setDate(endDate.getDate() - state.syncDays);
      } else {
        startDate = null
        endDate = null
      }

      const syncRequest: SyncRequest = {
        from_timestamp:  getTimeStamp(startDate),
        to_timestamp: getTimeStamp(endDate),
        notes: state.notes
      };
      const response = await ApiService.syncNotes(syncRequest);
      if (response.error) {
        // TODO: better error-code for token being invalid
        if (response.code === 401) {
          localStorage.removeItem('token');
          dispatch({ type: 'LOGOUT' });
          return;
        }

        dispatch({ type: 'ADD_TOAST', payload: { id: Date.now(), message: response.error, color: "red" } });
      } else {
        const server_newer = response.server_newer || [];
        const only_on_server = response.only_on_server || [];

        for (const note of [...server_newer, ...only_on_server]) {
          await DatabaseService.saveNote(note, false);
        }

        await loadLocalNotes();
        dispatch({ type: 'ADD_TOAST', payload: { id: Date.now(), message: t('sync.success'), color: "green" } });
      }
    } catch (error) {
      dispatch({ type: 'ADD_TOAST', payload: { id: Date.now(), message: t('sync.failed'), color: "yellow" } });
      console.error('同步失败:', error);
    } finally {
      dispatch({ type: 'SET_SYNCING', payload: false });
    }
  };

  const editingNote = state.editingNote;
  const setEditingNote = (note: Note|null) => {
    dispatch({type: 'SET_EDITING_NOTE', payload: note})
  };
  const handleCancelEdit = () => {
    setEditingNote(null)
  };
  const handleEditNote = async (note: Note) => {
    setEditingNote(note)
  }
  const handleDeleteNote = async (note: Note) => {
    console.log("deleting note: ", note)
    await DatabaseService.markNoteAsDeleted(note);
    await loadLocalNotes();
  }
  const handleSaveNote = async () => {
    const updatedNote = editingNote
    if(updatedNote == null) {
      console.error("unexpected: editingNote is null")
      return
    }

    await DatabaseService.saveNote(updatedNote);
    setEditingNote(null);
    await loadLocalNotes(); // 重新加载笔记
  };

  const editNoteHandleKeyDown = editorShortcutListener({save: handleSaveNote, cancel: handleCancelEdit})

  return (
    <div>
      <Toolbar 
        onSync={handleManualSync}
      />

      <ToastBox />

      {!state.isLoggedIn ? (
        <Login successCallback={async () => {
          await initializeApp();
        }} />
      ) : (
        <PullToRefresh onRefresh={handleManualSync}>
          <div className="flex">
            <ResolutionsPanel />
            <NotesPanel 
              notes={state.notes}
              editingNote={editingNote}
              onEditNote={handleEditNote}
              onDeleteNote={handleDeleteNote}
              onSaveNote={handleSaveNote}
              onCancelEdit={handleCancelEdit}
              setEditingNote={setEditingNote}
              refreshNotes={loadLocalNotes}
            />
          </div>
        </PullToRefresh>
      )}
    </div>
  );
}

export default App;

